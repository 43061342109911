<template>
  <select ref="el" v-model="model_value" class="form-select" v-bind="{...control_attrs}" @change="emit('change', $event)" multiple="true">
    <option v-if="include_blank !== false" value>{{ include_blank || "" }}</option>
    <slot />
  </select>
</template>

<script setup lang="ts">
import $ from 'jquery'
import { Validation } from "@/models"
import * as helper from "../helper"
import { ControlProps } from "../helper"
import { computed, provide, onMounted, onUpdated, ref } from "vue"
import 'bootstrap-multiselect/dist/js/bootstrap-multiselect.js'
import 'bootstrap-multiselect/dist/css/bootstrap-multiselect.css'

export interface Props extends ControlProps {
  validation?: Validation

  name?: string
  include_blank?: string | boolean
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  include_blank: false,
})

const emit = defineEmits<{
  change: [evenvt: Event]
}>()

const define_model_value = defineModel<any>()
const model_value = helper.modelValue(define_model_value)
const validation = helper.validation(props)

provide('model_value', model_value)

const el = ref(null)

const options = helper.buildControlConfig(props)
const control_attrs = computed(() => {
  const attrs = { class: [] } as any

  if (options.value.size == 'small') {
    attrs.class.push('form-select-sm')
  } else if (options.value.size == 'large') {
    attrs.class.push('form-select-lg')
  }

  if (validation.value.isInvaild()) {
    attrs.class.push("is-invalid")
  }

  if (options.value.disabled) {
    attrs.disabled = true
  }

  if (options.value.control_id) {
    attrs.id = options.value.control_id
  }

  return attrs
})

function initMultiselect() {
  let button_class = "btn btn-outline-secondary"
  if (options.value.size == 'small') {
    button_class += ' btn-sm'
  } else if (options.value.size == 'large') {
    button_class += ' btn-lg'
  }

  $(el.value).multiselect({
    enableClickableOptGroups: true,
    includeSelectAllOption: true,
    buttonClass: button_class,
    buttonContainer: '<div class="dropdown" />',

    templates: {
      button: '<button type="button" class="multiselect dropdown-toggle" data-bs-toggle="dropdown" data-flip="false"><span class="multiselect-selected-text"></span> <b class="caret"></b></button>',
      filter: '<li class="multiselect-item flex align-items-center"><div class="input-group"><span class="input-group-text"><i class="fas fa-search"></i></span><input class="form-control multiselect-search" type="text"></div></li>',
      filterClearBtn: '<button class="input-group-text btn btn-outline-secondary multiselect-clear-filter" type="button"><i class="fas fa-times"></i></button>',
      ul: '<ul class="multiselect-container dropdown-menu p-1 m-0"></ul>',
    },

    enableFiltering: true,
    enableFullValueFiltering: true,
    filterPlaceholder: '搜索',
    nonSelectedText: '尚未选择',
    nSelectedText: '项 (已选择)',
    allSelectedText: '全部 已选择',
    selectAllText: '全选',

    onChange: function() {
      model_value.value = $(this.$select).val()
    },
    onSelectAll: function() {
      model_value.value = $(this.$select).val()
    }
  })
}

onMounted(() => {
  initMultiselect()
})

onUpdated(() => {
  $(el.value).multiselect('destroy')
  initMultiselect()
})
</script>
