import { Klass } from '@/lib/transforms'
import { Attachment } from './Attachment'
import { CoursewareContent } from "./CoursewareContent"

export class SpeechTextContent extends CoursewareContent {
}

export class SpeechTextContentInfo extends SpeechTextContent {
  @Klass(Attachment) media_attachment: Attachment
}
