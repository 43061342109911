import { Klass } from '@/lib/transforms'
import { Subject } from './Subject'
import { Agent } from './Agent'
import { School } from './School'
import { EvaluationCoursewareRelation } from "./EvaluationCoursewareRelation"

export default class JointEvaluation {
  id!: number
  title!: string
  subject_id!: number

  @Klass(Date) published_at!: Date
  @Klass(Date) created_at!: Date
  @Klass(Date) updated_at!: Date
  @Klass(Date) deadline_at!: Date
  @Klass(Subject) subject!: Subject
  @Klass(Agent) agents: Agent[]
  @Klass(School) schools: School[]
  evaluation_courseware_relations: EvaluationCoursewareRelation[]
}
