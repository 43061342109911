import { Boolean, Klass } from '@/lib/transforms'

export class Notice {
  id: number
  category: string
  channels: string[]
  content: string
  creator_id: number
  creator_type: string
  mode: string
  publisher_name: string
  remark: string
  site_id: number
  title: string
  url: string

  @Boolean() modal: boolean
  @Klass(Date) created_at: Date
  @Klass(Date) expired_at: Date
  @Klass(Date) published_at: Date
  @Klass(Date) pushed_at: Date

  channel_list() {
    return this.channels.join(",")
  }
}

export class NoticeInfo extends Notice {
  creator_name: string
}
