import { Klass } from '@/lib/transforms'

export class PushNotificationRecord {
  id: number
  notice_id: number

  @Klass(Date) created_at: Date
  @Klass(Date) updated_at: Date
  @Klass(Date) published_at: Date
  @Klass(Date) pushed_at: Date
}
