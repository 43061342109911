import { Klass } from '@/lib/transforms'

export class Teacher {
  id: number
  name: string
  phone: string
  gender: string
  username: string
  extra_info: string
  nickname: string
  demo: boolean
  authcode: string
  usercode: string
  import_no: string
  alipay: string
  point: string
  phone_required: boolean
  personal_name_required: boolean
  site_id: number
  linked_services: string[]
  @Klass(Date) created_at!: Date
  @Klass(Date) last_sign_in_at: Date | null
  @Klass(Date) last_password_changed_at: Date | null
  @Klass(Date) actived_at: Date | null
}
