import _ from "lodash"

export class Validation {
  code: string
  state: "pending" | "invalid" | "valid"
  _messages: string[]
  children: Validation[] = []

  isInvaild(): boolean {
    return this.state == "invalid" || this.children.some(it => it.isInvaild())
  }

  get messages(): string[] {
    return [...this._messages, ..._.flatMap(this.children, it => it.messages)]
  }

  addMessages(messages: string[]) {
    this._messages.push(...messages)
  }

  clearMessages() {
    this._messages = []
  }
}
