import { Klass } from '@/lib/transforms'
import { Product } from './Product'

export default class Subscription {
  user_id: number
  name: string
  ticket_codes: string[]
  is_trial: boolean
  @Klass(Date) trial_start_on: Date
  @Klass(Date) trial_expired_on: Date
  @Klass(Date) expired_on: Date
  @Klass(Product) product: Product
}
