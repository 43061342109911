import { Klass } from '@/lib/transforms'
import { School } from './School'
import { Admin } from './Admin'

export class EventCase {
  id!: number
  state: string
  state_text: string
  event_name: string
  event_id: number
  @Klass(School) school: School
  @Klass(Admin) creator: Admin
  @Klass(Date) begin_at: Date
  @Klass(Date) close_at: Date
}
