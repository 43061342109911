import { Decimal, Klass } from "@/lib/transforms"
import Element from "./Element"
import Big from "big.js"

export default class TestPaperEntry {
  id: number
  type: "heading" | "text" | "suite" | "question"
  @Klass(Element) formatted_content: Element[]
  partition_id?: number
  question_id?: number
  question_no?: string
  suite_id?: number
  content: string
  content_html: string
  @Decimal() question_score: Big
}
