import { Klass, Boolean } from '@/lib/transforms'
import { Subject } from './Subject'

export class Edition {
  id!: number
  name!: string
  code!: string
  row_order!: number
  subject_id!: number
  @Boolean() schooling!: boolean

  @Klass(Subject) subject!: Subject
}
