import { Type } from 'class-transformer'
import BaseVersion from './BaseVersion'
import Versions from "./Versions"
import _ from 'lodash'

export default class ResourceInfo {
  id: number
  catalog: string
  original_filename: string

  @Type(() => Versions)
  versions: Versions

  // 'large', 'original', 'thumb'
  getVersion(keys: [keyof Versions] = [ 'original' ]): BaseVersion {
    let info: BaseVersion = new BaseVersion()
    if (_.isEmpty(this.versions)) return info
    for (const key of keys) {
      if (!_.isEmpty(this.versions[key])) {
        info = this.versions[key]
        break
      }
    }
    return info
  }
}
