import { Boolean, Decimal } from "@/lib/transforms"
import Big from "big.js"

export default class QuestionInput {
  answers_text: string
  case_rule: string
  correct_answers: string[]
  display_answers: string[]
  id: number
  input_name: string
  keypoints: any[]
  override_display_answers_text: string
  formatted_display_answers: string[]
  question_id: number
  row_order: number
  size: string
  keyboard: string

  @Boolean() skip_answer_required: boolean
  @Decimal() correct_match_rate: Big
  @Decimal() weight: Big
}
