import { Klass } from '@/lib/transforms'
import ExamPlan from './ExamPlan'
import { Teacher } from './Teacher'
import { School } from './School'
import { Courseware } from './Courseware'

export default class ExamPlanInfo extends ExamPlan {
  @Klass(Teacher) teacher: Teacher
  @Klass(School) school: School
  @Klass(Courseware) coursewares: Courseware[]
}
