import { Klass } from '@/lib/transforms'
import { Attachment } from './Attachment'

export class MediaResource {
  id: number
  height: number
  width: number
  content: string
  file_type: string
  file_type_text: string
  remark: string
  media_resource_url: string

  @Klass(Attachment) file_attachment!: Attachment
  @Klass(Date) created_at!: Date
}
