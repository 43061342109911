import { Type } from "class-transformer"
import ResourceInfo from "./ResourceInfo"

export class Question {
  id: number
  type: string
  hide_no: boolean
  suite_id: number
  suite_position: string
  answer_timeout: number
  formatted_content: any[]
  content_accessibility: any
  difficulty: number
  score_rule: string
  manual_check: boolean
  max_attachments_count: number
  formatted_solution: any
  waiting_time: string
  estimate_time: number
  collect_rate: string
  recording_begin_beep: string

  @Type(() => ResourceInfo)
  begin_media_info: ResourceInfo

  @Type(() => ResourceInfo)
  finish_media_info: ResourceInfo

  @Type(() => ResourceInfo)
  explan_media_info: ResourceInfo

  inputs: any[]
  category: any
  options: any[]

  instruction?: string
}
