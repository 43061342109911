import { Klass } from "@/lib/transforms"
import { Courseware } from './Courseware'
import { RepeatTextContentInfo } from './RepeatTextContent'
import { Tagging } from "./Tagging"

export class RepeatText extends Courseware {
  content_html: string
  precision: number
}

export class RepeatTextInfo extends RepeatText {
  @Klass(Tagging) taggings: Tagging[]
  @Klass(RepeatTextContentInfo) repeat_text_content: RepeatTextContentInfo
}
