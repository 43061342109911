import { UnprocessableEntityError } from "$commons/ui"
import { ErrorAccessDenied, ErrorUnauthorized } from "@/lib/requests"
import { Pagination, QiniuFile, ErrorsObject } from "@/models"
import Keyv from "@keyvhq/core"
import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from "axios"
import { plainToInstance } from "class-transformer"
import _ from "lodash"
import * as qs from "qs"
import * as rxjs from "rxjs"
import { Subscription } from "rxjs/internal/Subscription"
import URI from 'urijs'
import URITemplate from "urijs/src/URITemplate"

export interface PerformContext {
  $axios: AxiosInstance,
  $keyv: Keyv
}

type PerformOption = {
  ignore_columns?: string[]
}

export abstract class BaseRequest<T> {
  endpoint!: string
  method!: Method | string
  graph: string | null = null
  interpolations = {} as { [ x: string]: any }
  headers = {}
  query = {} as { [ x: string]: any }

  cancellation: Subscription | null
  $axios: AxiosInstance = null!
  $keyv: Keyv = null!
  data: any = {}
  config: AxiosRequestConfig = {}
  ctx: PerformContext = { $axios: null!, $keyv: null! }
  subject: rxjs.Subject<T>

  setup(ctx: { $axios: any, $keyv: any }, callback: (instance: this) => void | null = null): this {
    this.ctx = ctx

    if (callback) {
      callback(this)
    }

    return this
  }

  async perform(data?: any, options?: PerformOption): Promise<T> {
    const subject = this.doPerformSubject(_.omit(data, options?.ignore_columns ?? []))
    return await rxjs.lastValueFrom(subject)
  }

  doPerformSubject(data?: any) {
    if (this.subject) {
      return this.subject
    }

    this.subject = new rxjs.ReplaySubject()
    this.doPerform(data).then(it => {
      this.subject.next(it)
      this.subject.complete()
    }).catch(e => {
      this.subject.error(e)
    })

    return this.subject
  }

  async doPerform(data?: any): Promise<T> {
    this.data = data

    try {
      const resp = await this.axiosRequest(data)
      const result = this.processResponse(resp)
      return result
    } catch (e) {
      return await this.processError(e)
    }
  }

  async processError(e: any): Promise<T> {
    if (e instanceof AxiosError && e.response?.status === 403) {
      throw new ErrorAccessDenied()
    } else if (e instanceof AxiosError && e.response?.status === 401) {
      throw new ErrorUnauthorized()
    } else if (e instanceof AxiosError && e.response?.status === 422) {
      const errors = this.responseToObject(e.response, ErrorsObject)
      throw new UnprocessableEntityError(errors)
    } else {
      throw e
    }
  }

  abstract processResponse(response: AxiosResponse): T

  buildUrl() {
    const url = URITemplate(this.endpoint).expand(this.interpolations)
    const uri = new URI(url)
    const queryString = qs.stringify(this.query, { arrayFormat: "brackets" })
    return uri.query(queryString).toString()
  }

  cancellable(subscriptions: Subscription[]): this {
    this.cancellation = new rxjs.Subject().subscribe()
    subscriptions.push(this.cancellation)
    return this
  }

  axiosDefaultConfig(): AxiosRequestConfig {
    const controller = new AbortController()
    if (this.cancellation) {
      this.cancellation.add(() => {
        controller.abort()
      })
    }

    return {
      signal: controller.signal
    }
  }

  async axiosRequest(data: any): Promise<AxiosResponse> {
    const { $axios, $keyv } = this.ctx
    const config = <AxiosRequestConfig>{
      url: this.buildUrl(),
      method: this.method,
      headers: this.headers,
      ...this.config
    }

    if (this.graph) {
      config.headers!["X-Resource-Graph"] = this.graph
    }

    if (data) {
      const formData = data instanceof FormData ? data : this.buildFormData(data)
      config.data = formData
    }

    if (config.method === "GET") {
      const key = `${config.url}+${this.graph}`
      const cached = await $keyv.get(key)

      if (cached) {
        return cached
      } else {
        const resp = await $axios.request(config)
        await $keyv.set(key, resp)
        return resp
      }
    } else {
      const resp = await $axios.request(config)
      await $keyv.clear()
      return resp
    }
  }

  buildFormData(params: any) {
    const formData = new FormData()
    for (const name in params) {
      const value = params[name]
      this.fillFormData(formData, name, value)
    }
    return formData
  }

  fillFormData(form_data: FormData, name: string, value: any) {
    if (_.isArray(value)) {
      for (const [ key, val ] of value.entries()) {
        if (_.isPlainObject(val)) {
          this.fillFormData(form_data, `${name}[${key}]`, val)
        } else {
          this.fillFormData(form_data, `${name}[]`, val)
        }
      }
    } else if (value instanceof QiniuFile) {
      if (!value.key) {
        throw new Error("QiniuFile key is empty")
      }

      form_data.append(name, value.key)
    } else if (_.isPlainObject(value)) {
      for (const attr in value) {
        const val = value[attr]
        this.fillFormData(form_data, `${name}[${attr}]`, val)
      }
    } else {
      _.isNull(value) ? form_data.append(name, "") : form_data.append(name, value)
    }
  }

  responseToObject<K>(resp: AxiosResponse, klass: new () => K): K {
    return plainToInstance(klass, resp.data)
  }

  responseToArray<K>(resp: AxiosResponse<any, any>, klass: new () => K): K[] {
    return plainToInstance<K, K>(klass, resp.data)
  }

  responseToPage<K>(resp: AxiosResponse<any, any>, klass: new () => K) {
    const pagination = new Pagination<K>()
    pagination.list = this.responseToArray(resp, klass)
    pagination.build(resp)
    return pagination
  }
}
