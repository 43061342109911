import { Klass, Boolean } from '@/lib/transforms'
import { Attachment } from './Attachment'

export class CoursewareContent {
  id: number
  content: string
  content_html: string
  media_type: string
  estimate_time: number

  @Boolean() custom_estimate_time_enabled: Boolean | boolean
}

export class CoursewareContentInfo extends CoursewareContent {
  @Klass(Attachment) media_attachment: Attachment
}
