import { Klass } from '@/lib/transforms'

export class StudentRedistributionCheckingError {
  unmatch: number[]
}

export class StudentRedistributionItem {
  line_no: number
  grade_name: string
  clazz_name: string
  student_name: string
  state: string
  message: string
  action: string
}

export class StudentRedistribution {
  id: number
  grade_id: number
  school_id: number
  messages: string[]
  term_year: number
  state: string

  @Klass(StudentRedistributionItem) items?: StudentRedistributionItem[]
  @Klass(StudentRedistributionCheckingError) checking_errors?: StudentRedistributionCheckingError[]
  @Klass(Date) with_archived_at_clazzes?: Date
}
