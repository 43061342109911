<template>
  <div ref="el" class="dropdown">
    <button class="form-control flex text-nowrap" role="button" data-bs-toggle="dropdown" v-bind="control_attrs">
      <div :class="{ 'text-muted': model_value == null }">
        <template v-if="label_elements.length > 0">
          <component v-for="child in label_elements" :is="child" />
        </template>
      </div>
      <div class="ms-auto">
        <i class="fas fa-caret-down"></i>
      </div>
    </button>

    <ul class="dropdown-menu x-min-w-100">
      <template v-if="state == 'pending'">
        <slot name="pending"></slot>
      </template>
      <template v-else-if="state == 'loading'">
        <li><a class="dropdown-item disabled">加载中...</a></li>
      </template>
      <template v-else-if="state == 'loaded'">
        <slot name="loaded"></slot>
      </template>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { Validation } from "@/models"
import * as helper from "../helper"
import { ControlProps } from "../helper"
import { computed, provide, ref, onMounted, reactive, watch, nextTick } from "vue"

const el = ref(null! as HTMLDivElement)

onMounted(() => {
  el.value.addEventListener('show.bs.dropdown', (event) => {
    loadOptions(event)
  })
})

export interface Props extends ControlProps {
  validation?: Validation

  revision?: any
}

const props = withDefaults(defineProps<Props>(), {
  // disabled: false
})

const emit = defineEmits<{
  change: [evenvt: Event]
  load: [done: Function, revision: any]
}>()

const define_model_value = defineModel<any>()
const model_value = helper.modelValue(define_model_value)
const validation = helper.validation(props)

watch(computed(() => props.revision), () => {
  state.value = 'pending'
  model_value.value = null
})

provide('model_value', model_value)

const mapping = reactive(new Map<any, any>())
function register(value: any, children: any) {
  mapping.set(value, children)
}

provide('register', register)

const label_elements = computed(() => {
  return mapping.get(model_value.value) ?? []
})

const options = helper.buildControlConfig(props)
const control_attrs = computed(() => {
  const attrs = { class: [] } as any

  if (options.value.size == 'small') {
    attrs.class.push('form-control-sm')
  } else if (options.value.size == 'large') {
    attrs.class.push('form-control-lg')
  }

  if (validation.value.isInvaild()) {
    attrs.class.push("is-invalid")
  }

  if (options.value.disabled) {
    attrs.disabled = true
  }

  if (options.value.control_id) {
    attrs.id = options.value.control_id
  }

  return attrs
})

const state = ref('pending')

function loadOptions(event: any) {
  if (state.value != 'pending') {
    return
  }

  state.value = 'loading'
  emit('load', () => {
    state.value = 'loaded'
  }, props.revision)
}
</script>
