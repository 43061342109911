import { Klass } from '@/lib/transforms'

export class LessonPlan {
  id!: number
  title!: string
  state_text!: string
  state!: string
  schoolbook_id!: number
  unit_module_id!: number
  unit_id!: number
  @Klass(Date) created_at: Date
}
