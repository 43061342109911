import { createApp } from 'vue'
import AppTemplate from './AppTemplate.vue'

// shadcn
import '$commons/ui/style.css'

import '~/vendor/fontawesome'
// import '~/vendor/bootstrap'
import '~/vendor/coursewares'
import '~/vendor/selectize'
import './assets/stylesheets'

const app = createApp(AppTemplate)

import * as initializers from './initializers'
for (const key in initializers) {
  initializers[key](app)
}

app.mount('#app')
