import { Klass } from '@/lib/transforms'
import QuestionInput from './QuestionInput'

export default class AnswerInput {
  id: number
  answer_text: string
  raw_data: string
  details: string[]
  paper_id: number
  paper_type: string
  question_input_id: number
  score: number
}
