<template>
  <div class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
    <div class="text-center">
      <div v-if="(_.head(errors) instanceof ErrorUnauthorized)">
        <div class="text-red-500 text-center">
          <div class="text-4xl font-bold mb-2">无法访问</div>
          <p>身份验证失败</p>
        </div>
      </div>
      <div v-else-if="errors.length > 0">
        <div class="text-red-500 mb-24">
          <div class="text-4xl font-bold mb-2">未知错误</div>
          <p>{{ _.head(errors) }}</p>
        </div>
        <div class="text-start text-muted">
          <span>详细信息</span>
          <ul>
            <li v-for="error in errors" :key="error" class="list-group-item text-danger">
              {{ buildErrorMessage(error) }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ErrorUnauthorized } from "@/lib/requests"
import _ from "lodash"

const props = defineProps<{
  errors: any[]
}>()

function buildErrorMessage(error: Error) {
  return error?.stack ?? error
}
</script>
