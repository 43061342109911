import { Klass } from '@/lib/transforms'
import { Admin } from "./Admin"

export class JointEvaluationAdjustRecord {
  id!: number
  question_id!: number
  school_id!: number
  state_text: string
  adjustment_ratio: number
  @Klass(Date) created_at!: Date
  @Klass(Admin) admin: Admin
}
