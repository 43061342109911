import { Klass } from '@/lib/transforms'
import { Jackpot } from './Jackpot'

export class Coupon {
  id: number
  code: string
  clazz_code: string
  exchanged_count: number
  max_exchange_count: number
  no: string
  related_code: string
  remark: string
  status: string

  @Klass(Date) expired_on: Date
  @Klass(Date) created_at: Date
  @Klass(Date) updated_at: Date
}

export class CouponInfo extends Coupon {
  @Klass(Jackpot) jackpot?: Jackpot
}
