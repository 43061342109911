import { Klass } from "@/lib/transforms"
import { TestPaper } from "./TestPaper"

export class ShengtongExamRecord {
  id!: number
  batch_no!: string
  exam_no!: string
  homework_id!: number
  message!: string | null
  state!: string
  teacher_code!: string | null
  teacher_name!: string | null
  student_code!: string | null
  student_name!: string | null
  test_paper_code!: string | null

  @Klass(Date) score_time!: Date | null
  @Klass(Date) created_at!: Date | null
  @Klass(TestPaper) test_paper: TestPaper
}
