import { Klass } from '@/lib/transforms'

export class Event {
  id!: number
  name: string
  one_time_only: boolean
  state: string
  state_text: string
  site_id: number
  wechat_url: string

  @Klass(Date) updated_at: Date
  @Klass(Date) created_at: Date
}
