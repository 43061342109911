import { Klass } from '@/lib/transforms'
import { Pagination } from '@/models/Pagination'
import { SchoolIncomeStatistic } from './SchoolIncomeStatistic'

export class SchoolIncomeSummary {
  @Klass(Pagination<SchoolIncomeStatistic>) pagination!: Pagination<SchoolIncomeStatistic>

  total_personal_paid_amount!: number
  total_personal_paid_agent_cash!: number

  total_personal_refunded_amount!: number
  total_personal_refunded_agent_cash!: number

  total_agent_paid_amount!: number
  total_agent_paid_point!: number

  total_agent_refunded_amount!: number
  total_agent_refunded_point!: number
}


