import { Klass } from '@/lib/transforms'

class Metadata {
  width?: number
  height?: number
  duration?: string
}

export class AttachmentVersion {
  url!: string
  md5!: string
  content_type!: string
  @Klass(Metadata) metadata!: Metadata

  buildDownloadUrl(originalFilename: string): string {
    return this.url + '?attname=' + originalFilename
  }
}


