import { Klass } from '@/lib/transforms'

export class WechatAuthentication {
  id!: number
  name!: string
  openid!: string
  demo!: string
  followed!: string
  perishable_token: string

  @Klass(Date) created_at: Date
}
