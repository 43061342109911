<template>
  <Error v-if="page.errors.length > 0" :errors="page.errors" />
  <router-view v-else />
</template>

<script setup lang="ts">
import { onErrorCaptured } from "vue"
import Error from "@/layouts/Error.vue"
import { usePageStore } from "./store/page"

const page = usePageStore()

onErrorCaptured((err, vm, info) => {
  console.error(err)
  if (page.errors.length == 0) {
    page.errors.push(err)
  }
  return false
})
</script>
