import { Type } from 'class-transformer'

class Metadata {
  width: number
  height: number
  duration: string
}

export default class BaseVersion {
  url: string
  md5: string
  content_type: string

  @Type(() => Metadata)
  metadata: Metadata

  buildDownloadUrl(originalFilename: string): string {
    return this.url + '?attname=' + originalFilename
  }
}


