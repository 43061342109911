export default class UnitText {
  id: number
  code: string
  title: string
  state: string
  content: string
  row_order: number
  offline_reason: string
  remark: string

  is_online() {
    return this.state == 'online'
  }

  is_offline() {
    return this.state == 'offline'
  }
}
