import { Transform, Type, plainToClass } from "class-transformer"
import _ from 'lodash'
import Big from 'big.js'

export function Boolean() {
  return Transform(({ value }) => {
    if (value === null || value === "" || value === undefined) {
      return null
    }

    return [1, "1", true, "true", "TRUE", "True"].includes(value)
  })
}

const transformNumber = (value: any): number | number[] | null => {
  if (value == null || value == "") {
    return null
  }

  if (value instanceof Array) {
    return value.map(v => transformNumber(v)) as number[]
  } else {
    return _.toNumber(value)
  }
}

export const Number = Transform(({ value }) => transformNumber(value))

export function Decimal() {
  return Transform(({ value }) => {
    if (value == null || value == "") {
      return null
    }

    return Big(value)
  })
}

export function Enum<T>(e: T) {
  return Transform(({ value }) => {
    if (value == null || value == "") {
      return null
    }

    return e[value as keyof T]
  })
}

export const Date = Klass(globalThis.Date)
export const String = Klass(globalThis.String)

export function Klass<T>(klass: new () => T) {
  return Type(() => klass)
}

export function ToMap<T>(klass: new () => T) {
  return Transform(value => {
    let map = new Map<string, T>()
    for (let entry of Object.entries(value.value)) {
      map.set(entry[0], plainToClass(klass, entry[1]));
    }
    return map;
  }, { toClassOnly: true })
}
