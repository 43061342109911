import { Pagination } from './Pagination'
import * as t from '@/lib/transforms'
import { EntityIndex, EntityRepo } from './EntityRepo'

export class Ticket {
  id: number
  code: string
  name: string
  grouping: string
  assortment_id: number
  assortment_name: string
}

export class TicketStat {
  assortment_id: number
  grouping: string
  count: number
}

export class TicketPage extends Pagination<Ticket> {
  @t.Klass(Ticket) list: Ticket[] = []
  @t.Klass(TicketStat) stats: TicketStat[] = []
}

export class TicketRepo extends EntityRepo<Ticket> {
  code = new EntityIndex<string | null, Ticket>(it => it.code)
  group_id = new EntityIndex<number | null, Ticket>(it => it.assortment_id)

  override buildIndex(entity: Ticket): void {
    this.id.add(entity)
    this.code.add(entity)
    this.group_id.add(entity)
  }
}
