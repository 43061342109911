import { Klass } from '@/lib/transforms'
import { Tag } from './Tag'

export class Tagging {
  id: number
  context: string
  tag_id: number
  taggable_id: number
  taggable_type: string
  tagger_id: number
  tagger_type: string

  @Klass(Tag) tag: Tag
}
