import { Klass } from '@/lib/transforms'

export default class ExamPlan {
  id!: number
  batch_no: string
  examination_id: number
  school_id: number
  teacher_id: number
  @Klass(Date) created_at: Date
  @Klass(Date) updated_at: Date
}
