import { Klass } from "@/lib/transforms"
import { Courseware, CoursewareInfo } from './Courseware'
import { InteractiveReadingContentInfo } from './InteractiveReadingContent'

export class InteractiveReading extends Courseware {
  content_html: string
  precision: number
}

export class InteractiveReadingInfo extends CoursewareInfo {
  @Klass(InteractiveReadingContentInfo) interactive_reading_content: InteractiveReadingContentInfo
}
