import { Klass } from '@/lib/transforms'

export class Region {
  id: number
  code: string
  name: string
  remark: string
  enable_teaching: boolean
  with_common_bundle: boolean
  default_school_features: string[]
  site_id: number
}
