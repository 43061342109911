<template>
  <input v-model="model_value" class="form-control" :name="name" v-bind="control_attrs" :placeholder="placeholder" :size="size">
</template>

<script setup lang="ts">
import { Validation } from '@/models'
import * as helper from "../helper"
import { ControlProps } from '../helper'
import { InputHTMLAttributes, watch } from 'vue'

export interface Props extends ControlProps, /* @vue-ignore */ Partial<InputHTMLAttributes> {
  validation?: Validation

  name?: string
  placeholder?: string
  size?: number
}

const props = defineProps<Props>()

const define_model_value = defineModel<any>()
const model_value = helper.modelValue(define_model_value)
const validation = helper.validation(props)

const options = helper.buildControlConfig(props)
const control_attrs = helper.buildControlAttrs(options, validation)
</script>
