import { Klass } from "@/lib/transforms"

export class WxpayPayment {
  id: number
  token: string
  state: string
  state_text: string

  @Klass(Date) created_at: Date
  @Klass(Date) updated_at: Date
}
