import { Klass, Boolean } from "@/lib/transforms"
import { Attachment } from "./Attachment"

export class Site {
  id: number
  name: string
  code: string
  domain: string
  wechat_appid: string
  wechat_secret: string
  wechat_workathon_notify_template_id: string
  wechat_weekly_report_template_id: string
  wechat_introduction_template_id: string
  wechat_subscription_expire_template_id: string
  wechat_order_create_template_id: string
  wechat_mp_username: string
  wechat_leaf_authentication_url: string
  sms_register_template_id: string
  sms_common_template_id: string
  small_corn_android_key: string
  small_corn_ios_key: string
  registered_redirect_url: string
  client_access_token: string
  row_order: number

  @Boolean() allow_default_school: boolean
  @Klass(Attachment) icon_attachment: Attachment
}
