import { Klass } from '@/lib/transforms'
import { Attachment } from './Attachment'
import { CoursewareContent } from "./CoursewareContent"

export class TestPaperContent extends CoursewareContent {
  audio_text_html: string
}

export class TestPaperContentInfo extends TestPaperContent {
  @Klass(Attachment) media_attachment: Attachment
  @Klass(Attachment) attachments: Attachment[]
}
