import { Klass } from '@/lib/transforms'
import Student from './Student'

export default class AnswerSheet {
  id: number
  student_id: number
  test_papaer_id: number
  score: number
  spent_time: number
  homework_paper_id: number
  processing_questions_count: number

  @Klass(Date) created_at: Date
  @Klass(Student) student: Student
}
