import { Klass, Decimal } from '@/lib/transforms'
import Big from 'big.js'

export class Agent {
  id!: number
  name?: string
  phone!: string
  schools_count!: number

  @Decimal() balance!: Big
  @Decimal() point!: Big
  @Decimal() discount_rate!: Big
  @Decimal() rebate_rate!: Big

  role!: 'schoolmen' | 'agent' | 'nobody'
  @Klass(String) roles!: string[]

  teacher_id?: number

  @Klass(Date) created_at!: Date
}