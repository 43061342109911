import { Boolean } from "@/lib/transforms"

export class TestPaperPartition {
  id: number
  title: string
  estimate_time: number
  questions_count: number
  row_order: number
  courseware_content_id: number
  total_score: number

  @Boolean() include_manual_check: boolean
  @Boolean() show_audio: boolean
  @Boolean() show_title: boolean
}
