<template>
  <input :style="`background: linear-gradient(var(--bs-primary-bg-subtle), var(--bs-primary-bg-subtle)) no-repeat; background-size: ${progress}% 100%;`"
   @change="onInputChanged" type="file" class="form-control qiniu" :name="name" v-bind="{ ...control_attrs, multiple, qiniu_name }">
</template>

<script setup lang="ts">
import _ from 'lodash'
import { Validation, QiniuFile } from '@/models'
import * as helper from "../helper"
import { ControlProps } from '../helper'
import { InputHTMLAttributes, computed } from 'vue'

export interface Props extends ControlProps, /* @vue-ignore */ Partial<InputHTMLAttributes> {
  validation?: Validation

  multiple?: boolean
  name?: string
  qiniu_name?: string
}

const props = withDefaults(defineProps<Props>(), {
  multiple: false
})

const define_model_value = defineModel<QiniuFile | QiniuFile[] | null>()
const model_value = helper.modelValue(define_model_value)
const validation = helper.validation(props)

const options = helper.buildControlConfig(props)
const control_attrs = helper.buildControlAttrs(options, validation)

function onInputChanged(event: Event) {
  const target = event.target as HTMLInputElement
  const files = Array.from(target.files ?? []).map(file => new QiniuFile(file))

  model_value.value = props.multiple ? files : files[0]
}

const progress = computed(() => {
  if (!model_value.value) {
    return 0
  } else if (model_value.value instanceof Array) {
    const sum = _.sum(model_value.value.map(file => file.progress))
    return sum / model_value.value.length
  } else {
    return model_value.value.progress
  }
})
</script>
