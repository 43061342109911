import "reflect-metadata"
import { Buffer } from "buffer"
globalThis.Buffer = Buffer

import '../_stimulus'
import '../widgets/selectize'
import '../widgets/easymde'
import '../widgets/copy'

import '../backend/main'
