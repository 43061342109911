import { Markdown } from "@/models"
import { AxiosResponse } from "axios"
import { BaseRequest } from "./BaseRequest"

export default {
  Preview: class extends BaseRequest<Markdown> {
    method = "POST"
    endpoint = "/api/markdown/preview"

    processResponse(response: AxiosResponse<any, any>) {
      return this.responseToObject(response, Markdown)
    }
  }
}
