import { Klass } from '@/lib/transforms'
import { Subject } from './Subject'

export class CoursewareFamily {
  id!: number
  name!: string
  syscode!: string
  row_order!: number
  subject_id!: number

  @Klass(Subject) subject!: Subject
}
