import _ from 'lodash'

export class EntityIndex<K, T extends { id: any }> {
  lookup: (entity: T) => K
  data = new Map<K, T[]>
  loaded = new Set<K>()

  constructor(lookup: (entity: T) => K) {
    this.lookup = lookup
  }

  add(entity: T) {
    const key = this.lookup(entity)
    if (!this.data.has(key)) {
      this.data.set(key, [])
    }

    const list = this.data.get(key)
    const index = list!.findIndex(it => it.id == entity.id)
    if (index < 0) {
      list!.push(entity)
    } else {
      list![index] = entity
    }
  }

  findAll(key: K) {
    return this.data.get(key) ?? []
  }

  find(key: K) {
    return this.findAll(key)[0]
  }

  markAsLoaded(key: K) {
    this.loaded.add(key)
  }

  isLoaded(key: K) {
    return this.loaded.has(key)
  }
}

export class EntityRepo<T extends { id: any }> {
  data: T[] = []
  id = new EntityIndex<number, T>(it => it.id)

  setup(entities: T[]) {
    this.data = []
    this.addAll(entities)

    return this
  }

  addAll(entities: T[]) {
    for (const entity of entities) {
      this.id.add(entity)
      this.buildIndex(entity)
      this.data.push(entity)
    }
  }

  find(id: number): T | null {
    return this.id.findAll(id)[0]
  }

  keys(): number[] {
    return Array.from(this.id.data.keys())
  }

  values(): T[] {
    return this.data
  }

  valuesAt(ids: number[]): T[] {
    return _.compact(ids.map(id => this.find(id)))
  }

  buildIndex(entity: T) {}
}
