import { Type } from 'class-transformer'
import _ from 'lodash'
import BaseVersion from './BaseVersion'

class Thumb extends BaseVersion {
}

class Original extends BaseVersion {
}

export default class Versions {
  @Type(() => Thumb)
  thumb: Thumb

  @Type(() => Original)
  original: Original
}
