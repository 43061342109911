import { Klass } from "@/lib/transforms"
import { Courseware } from "./Courseware"
import { Schoolbook } from "./Schoolbook"

export class EvaluationCoursewareRelation {
  id: number
  exam_time: number

  @Klass(Courseware) courseware: Courseware
  @Klass(Schoolbook) schoolbook: Schoolbook
}
