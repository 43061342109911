import { Klass } from "@/lib/transforms"
import { Courseware } from './Courseware'
import { CoursewareFamily } from "./CoursewareFamily"
import { SpeechTextContentInfo } from './SpeechTextContent'
import { Tagging } from "./Tagging"

export class SpeechText extends Courseware {
  content_html: string
  precision: number
}

export class SpeechTextInfo extends SpeechText {
  @Klass(Tagging) taggings: Tagging[]
  @Klass(CoursewareFamily) family: CoursewareFamily
  @Klass(SpeechTextContentInfo) speech_text_content: SpeechTextContentInfo
}
