import type { BaseRequest } from '@/lib/requests'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export const usePageStore = defineStore('page', () => {
  const caches = new Map<any, any>()
  const id = ref(1)
  const router = useRouter()
  const route = useRoute()
  const errors = ref([] as Error[])

  function request<T extends BaseRequest<any>>(klass: new () => T): T {
    if (caches.has(klass)) {
      console.log('page cached')
      return caches.get(klass)
    } else {
      const request = new klass()
      caches.set(klass, request)
      return request
    }
  }

  function clear() {
    caches.clear()
  }

  function reload() {
    clear()
    id.value += 1
    router.push(route.fullPath)
  }

  function clearError() {
    errors.value = []
  }

  return { request, clear, id, reload, errors, clearError }
})
