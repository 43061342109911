import { Klass } from '@/lib/transforms'

export class BatchCouponsForm {
  jackpot_id: number
  no: string
  quantity: number
  max_exchange_count: number
  remark: string
  related_code: string

  @Klass(Date) expired_on: Date
}
