import { Klass } from "@/lib/transforms"
import { Courseware } from './Courseware'
import { CosplayContentInfo } from './CosplayContent'
import { Tagging } from "./Tagging"

export class Cosplay extends Courseware {
  content_html: string
  precision: number
}

export class CosplayInfo extends Cosplay {
  @Klass(Tagging) taggings: Tagging[]
  @Klass(CosplayContentInfo) cosplay_content: CosplayContentInfo
}
