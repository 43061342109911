<script setup lang="ts">
import { computed, useAttrs, type HTMLAttributes } from 'vue'
import { Primitive, type PrimitiveProps } from 'radix-vue'
import { ButtonVariances, type PresetId } from './types'
import { cn } from '../utils'
import type { VariantProps } from 'class-variance-authority'
import { RouteLocationRaw, RouterLink } from 'vue-router'

type ButtonVariancesType = typeof ButtonVariances
type ButtonVarianceTypes = { [P in keyof ButtonVariancesType]: VariantProps<ButtonVariancesType[P]> }

interface Props extends PrimitiveProps {
  variant?: ButtonVarianceTypes[keyof ButtonVarianceTypes]['variant']
  size?: ButtonVarianceTypes[keyof ButtonVarianceTypes]['size']
  class?: HTMLAttributes['class']
  preset?: PresetId
  to?: string | RouteLocationRaw
}

const props = withDefaults(defineProps<Props>(), {
  as: 'button',
  preset: 'standard',
})

const variance = computed(() => ButtonVariances[props.preset])
const attrs = useAttrs()
</script>

<template>
  <Primitive :to="to" :as="attrs.disabled ? 'button' : props.to ? RouterLink : as" :as-child="asChild" :class="cn(variance({ variant: variant as any, size }), props.class)">
    <slot></slot>
  </Primitive>
</template>
