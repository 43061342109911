<template>
  <textarea ref="el" v-model="model_value" :rows="rows" class="form-control markdown1" :name="name" v-bind="control_attrs" />
</template>

<script setup lang="ts">
import $ from 'jquery'
import { Validation } from '@/models'
import * as helper from "../helper"
import { ControlProps } from '../helper'
import EasyMDE from 'easymde'
import * as requests from '@/lib/requests'
import { ref, onMounted, getCurrentInstance } from "vue"
import 'easymde/dist/easymde.min.css'

interface Props extends ControlProps {
  validation?: Validation
  name?: string
  rows?: number
}

const props = defineProps<Props>()

const el = ref(null! as HTMLSelectElement)
const define_model_value = defineModel<any>()
const model_value = helper.modelValue(define_model_value)
const validation = helper.validation(props)
const proxy = getCurrentInstance()!.proxy!

const options = helper.buildControlConfig(props)
const control_attrs = helper.buildControlAttrs(options, validation)

onMounted(() => {
  const easymde = new EasyMDE({
    element: el.value,
    status: false,
    previewRender(plainText, preview) {
      const content = plainText
      new requests.markdown.Preview().setup(proxy).perform({ content }).then(data => { preview.innerHTML = data.content })

      return 'loading...'
    }
  })

  easymde.codemirror.on('change', () => {
    model_value.value = easymde.value()
  })
})
</script>
