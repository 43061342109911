<template>
  <select ref="data" v-bind="{...control_attrs, multiple}" class="d-none">
    <option v-if="include_blank !== false" value>{{ include_blank || "" }}</option>
    <slot />
  </select>
  <select ref="el" v-bind="{ multiple }"></select>
</template>

<script setup lang="ts">
import { Validation } from "@/models"
import '@selectize/selectize'
import '@selectize/selectize/dist/css/selectize.css'
import $ from 'jquery'
import { computed, onMounted, onUnmounted, ref, watch } from "vue"
import * as helper from "../helper"
import { ControlProps } from "../helper"

export interface Props extends ControlProps {
  validation?: Validation

  name?: string
  include_blank?: string | boolean
  create?: boolean

  multiple?: string
}

const props = withDefaults(defineProps<Props>(), {
  create: false,
  disabled: false,
  include_blank: false,
})

const emit = defineEmits<{
  change: [evenvt: Event]
}>()

const el = ref(null! as HTMLSelectElement)
const data = ref(null! as HTMLSelectElement)
const define_model_value = defineModel<any>()
const model_value = helper.modelValue(define_model_value)
const validation = helper.validation(props)

const options = helper.buildControlConfig(props)
const control_attrs = computed(() => {
  const attrs = { class: [] } as any

  if (options.value.size == 'small') {
    attrs.class.push('form-select-sm')
  } else if (options.value.size == 'large') {
    attrs.class.push('form-select-lg')
  }

  if (validation.value.isInvaild()) {
    attrs.class.push("is-invalid")
  }

  if (options.value.control_id) {
    attrs.id = options.value.control_id
  }

  return attrs
})

const instance = ref(null as any | null)
function initSelectize() {
  if (instance.value) {
    instance.value.destroy()
  }

  el.value.innerHTML = data.value.innerHTML
  const $el = $(el.value) as any

  const config = {
    respect_word_boundaries: false,
    delimiter: ',',
    persist: false,
    create: false,
    onChange: function(value: any) {
      model_value.value = value
    }
  } as any

  if (props.create) {
    config.create = function(input: any) {
      return {
        value: input,
        text: input
      }
    }
  }

  instance.value = $el.selectize(config)[0].selectize
  instance.value.setValue(model_value.value)

  if (options.value.disabled) {
    instance.value.disable()
  }
}

watch(computed(() => options.value.disabled), (value) => {
  if (!instance.value) {
    return
  }

  if (value) {
    instance.value.disable()
  } else {
    instance.value.enable()
  }
})

const observer = new MutationObserver(function() {
  initSelectize()
})

onMounted(() => {
  observer.observe(data.value, { childList: true })
  console.log('onMounted')
  initSelectize()
})

onUnmounted(() => {
  observer.disconnect()
})
</script>
