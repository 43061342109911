import { Site } from "./Site"
import { Klass } from '@/lib/transforms'

export class Client {
  id!: number
  name: string
  uid: string
  secret: string
  redirect_uri: string
  access_token_expires_in: number

  @Klass(Site) site: Site
}
