import { Klass, Boolean } from "@/lib/transforms"
import { Subject } from "./Subject"

export class SiteSubjectRelation {
  id!: number
  site_id!: number
  subject_id!: number
  description!: string
  cover_url!: string

  @Klass(Subject) subject!: Subject
}
