<template>
  <Transfer
    v-model:target-keys="model_value"
    v-model:selected-keys="selected_keys"
    :data-source="data_source"
    :render="item => item.title"
    :list-style="{ 'flex-grow': 1 }"
    :disabled="control_attrs.disabled" />
</template>

<script setup lang="ts">
import { Validation } from '@/models'
import { Transfer } from 'ant-design-vue'
import { computed, ref } from 'vue'
import * as helper from "../helper"
import { ControlProps } from '../helper'

interface Props extends ControlProps {
  validation?: Validation

  name?: string
  collection: any[]
  selected_collection: any[]

  label_method: string
  value_method: string
}

const props = defineProps<Props>()

const define_model_value = defineModel<any>()
const model_value = helper.modelValue(define_model_value)
const validation = helper.validation(props)

const options = helper.buildControlConfig(props)
const control_attrs = helper.buildControlAttrs(options, validation)
const selected_keys = ref([])

interface TransferItem {
  key: string
  title: string
}

const data_source = computed(() => {
  return [...props.selected_collection, ...props.collection].map((item) => {
    return <TransferItem>{
      key: item[props.value_method],
      title: item[props.label_method],
    }
  })
})

</script>
