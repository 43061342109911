import { Klass } from '@/lib/transforms'

export class ExaminationParsingRecord {
  id!: number
  name!: string
  state!: string
  state_text!: string
  message!: string
  clazzes_info!: string[]
  students_info!: string[]
  file_url: string
  @Klass(Date) created_at!: Date
  @Klass(Date) updated_at!: Date
}
