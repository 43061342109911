import { Klass } from '@/lib/transforms'
import { School } from './School'
import JointEvaluation from './JointEvaluation'
import { Grade } from './Grade'

export default class Examination {
  id!: number
  name!: string
  subject_id!: number
  subject_name!: string
  state: string
  state_text!: string
  mode_text!: string
  assign_mode_text!: string
  allow_manual!: string
  publish_result_rule_text!: string
  grade_codes!: string[]

  @Klass(Date) released_at!: Date
  @Klass(Date) deadline_at!: Date
  @Klass(Date) created_at!: Date
  @Klass(Date) updated_at!: Date
  @Klass(Grade) grades: Grade[]
  @Klass(JointEvaluation) joint_evaluation: JointEvaluation
}
