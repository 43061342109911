<template>
  <input type="radio" v-model="model_value" :value="value" :class="cn(`
    aspect-square h-4 w-4 rounded-full border border-primary text-primary shadow
    focus:outline-none focus-visible:ring-1 focus-visible:ring-ring
    disabled:cursor-not-allowed disabled:opacity-50
  `, props.class)">
</template>

<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import { cn } from '../utils'

const model_value = defineModel<any[]>({ required: true })
const props = defineProps<{
  class?: HTMLAttributes['class'],
  value: any
}>()
</script>
