import { Klass } from '@/lib/transforms'
import { Attachment } from './Attachment'
import { CoursewareContent } from "./CoursewareContent"

export class InteractiveReadingContent extends CoursewareContent {
}

export class InteractiveReadingContentInfo extends InteractiveReadingContent {
  @Klass(Attachment) media_attachment: Attachment
  @Klass(Attachment) attachments: Attachment[]
}
