import { Role } from './Role'
import { Klass } from '@/lib/transforms'

export class Admin {
  id: number
  name: string
  email: string
  phone: string
  auth_code: string
  blind: boolean
  superadmin: boolean

  @Klass(Date)
    last_sign_in_at: Date

  @Klass(Role)
    roles: Role[]
}
