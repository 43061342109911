import { setupLayouts } from 'virtual:meta-layouts'
import { createRouter, createWebHistory } from "vue-router"
import { routes } from 'vue-router/auto/routes'

import { App } from "vue"

const routes_with_layout = setupLayouts(routes)

const router = createRouter({
  history: createWebHistory(process.env.RAILS_RELATIVE_URL_ROOT),
  scrollBehavior() {
    return { top: 0, left: 0 }
  },
  routes: routes_with_layout
})

export default function(app: App) {
  app.config.globalProperties.router = router
  app.use(router)
}
