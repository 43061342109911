import { Klass } from '@/lib/transforms'
import { Attachment } from './Attachment'
import { Schoolbook } from './Schoolbook'

export class Spread {
  id: number
  site_id: number
  subject_id: number
  title: string
  url: string
  position: string

  @Klass(Date) expired_at!: Date
  @Klass(Date) published_at!: Date
}

export class SpreadInfo extends Spread {
  poster_url: string

  @Klass(Attachment) poster_attachment: Attachment
  @Klass(Schoolbook) schoolbooks: Schoolbook[]
}
