import { Boolean } from '@/lib/transforms'

export default class Textbook {
  id: number
  author: string
  code: string
  description: string
  factions: string[]
  folder_id: number
  lookup_by: string
  memo: string
  name_with_remark: string
  name: string
  systag: string
  student_grouping?: string
  student_position: number
  student_ticket_code: string
  subject_id: number
  teacher_grouping?: string
  teacher_position: number
  teacher_ticket_code: string
  visible: string
  prompt_text: string
  permit_courseware_attributes: string[]

  get name_with_code() {
    return this.name + " - " + this.code
  }

  @Boolean() always_show_correct: boolean
  @Boolean() prompt_enabled: boolean
  @Boolean() collectable: boolean
}
