import { Klass } from '@/lib/transforms'
import { Grade, Bundle } from '@/models'

export class JackpotBundleRelation {
  id: number
  related_code: string

  @Klass(Grade) grade: Grade
  @Klass(Bundle) bundle: Bundle
}
