import { Klass } from '@/lib/transforms'
import { Agent, City, District, Region, Clazz } from '../models'
import { Province } from './Province'
import { ClazzStat } from './ClazzStat'
import _ from 'lodash'

type Phase = 'elementary' | 'junior' | 'high'

export class School {
  id!: number
  name!: string
  authcode!: string
  student_authcode!: string
  phase_list: Phase[] = []
  primary_school_graduation_grade: 'grade05' | 'grade06' | null = null
  remark?: string
  state!: 'online' | 'discard'
  state_text: string
  address?: string
  discard_reason?: string

  province_id!: number
  city_id!: number
  site_id!: number
  district_id!: number
  region_id?: number
  agent_id?: number

  allow_personal_purchase: boolean = false

  @Klass(Province) province!: Province
  @Klass(City) city!: City
  @Klass(District) district!: District
  @Klass(Agent) agent?: Agent

  @Klass(Date) created_at!: Date
  @Klass(Date) updated_at!: Date
}

export class SchoolInfo extends School {
  @Klass(Region) region!: Region
  @Klass(ClazzStat) clazzes_stats: ClazzStat[]
  students_counts: {
    actived: number
    inactived: number
  }

  total_students_count() {
    return _(this.students_counts).values().sum()
  }
}
